import { createRoot } from "react-dom/client";
import { EmptyAppFrame } from "../shared/AppFrame";
import { AppOffline } from "./AppOffline";
import { Error } from "./Error";
import { NotFound } from "./NotFound";
import { Unauthorised } from "./Unauthorised";

type PageData = {
  type: "Error" | "NotFound" | "Unauthorised" | "AppOffline";
};

export declare const pageData: PageData;

const container = document.getElementById("app");
const root = createRoot(container);

root.render(
  <EmptyAppFrame>
    {pageData.type === "AppOffline" ? (
      <AppOffline />
    ) : pageData.type == "NotFound" ? (
      <NotFound />
    ) : pageData.type == "Unauthorised" ? (
      <Unauthorised />
    ) : (
      <Error />
    )}
  </EmptyAppFrame>
);
