// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./not-found.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./not-found_2x.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.a5xf3vHPBrr47puSofUw {
  min-height: 570px;
  margin: 50px auto 30px auto;
  background-repeat: no-repeat;
  background-position: 0 170px;
  text-align: center;
  width: 600px;
}

.a5xf3vHPBrr47puSofUw h1 {
  font-size: 22px;
}

.a5xf3vHPBrr47puSofUw p {
  line-height: 24px;
  font-size: 16px;
}

.wX8EYmwpVFH1ltMxlL97 {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}

.vkyEgRJSicr090zEewEg {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}

@media only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-device-pixel-ratio: 2) {
  .wX8EYmwpVFH1ltMxlL97,
  .vkyEgRJSicr090zEewEg {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
    -webkit-background-size: 500px 231px;
    background-size: 500px 231px;
  }
}
`, "",{"version":3,"sources":["webpack://./Error/App.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,2BAA2B;EAC3B,4BAA4B;EAC5B,4BAA4B;EAC5B,kBAAkB;EAClB,YAAY;AACd;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,iBAAiB;EACjB,eAAe;AACjB;;AAEA;EAEE,yDAAsC;AACxC;;AAEA;EAEE,yDAAsC;AACxC;;AAEA;;EAEE;;IAEE,yDAAyC;IACzC,oCAAoC;IACpC,4BAA4B;EAC9B;AACF","sourcesContent":[".container {\n  min-height: 570px;\n  margin: 50px auto 30px auto;\n  background-repeat: no-repeat;\n  background-position: 0 170px;\n  text-align: center;\n  width: 600px;\n}\n\n.container h1 {\n  font-size: 22px;\n}\n\n.container p {\n  line-height: 24px;\n  font-size: 16px;\n}\n\n.notFoundContainer {\n  composes: container;\n  background-image: url(./not-found.png);\n}\n\n.errorContainer {\n  composes: container;\n  background-image: url(./not-found.png);\n}\n\n@media only screen and (-webkit-min-device-pixel-ratio: 2),\n  only screen and (min-device-pixel-ratio: 2) {\n  .notFoundContainer,\n  .errorContainer {\n    background-image: url(./not-found_2x.png);\n    -webkit-background-size: 500px 231px;\n    background-size: 500px 231px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `a5xf3vHPBrr47puSofUw`,
	"notFoundContainer": `wX8EYmwpVFH1ltMxlL97 a5xf3vHPBrr47puSofUw`,
	"errorContainer": `vkyEgRJSicr090zEewEg a5xf3vHPBrr47puSofUw`
};
export default ___CSS_LOADER_EXPORT___;
