import styles from "./App.css";

export function Unauthorised() {
  return (
    <div className={styles.container}>
      <h1>Unauthorised</h1>
      <p>Sorry, you don&apos;t have permission to view this page.</p>
      <p>
        You may need to <a href="/login">Login</a> with a different email
        address.
      </p>
    </div>
  );
}
