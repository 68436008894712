import * as React from "react";
import {
  AiAssistantConversationData,
  AiAssistantMessage,
  AiAssistantStreamData,
} from "@server-types/ai-assistant";
import { Alert } from "../Alert";
import Button from "../Button";
import Spinner from "../Spinner";
import { Stack } from "../Stack";
import { insightFetch } from "../insightFetch";
import {
  aiAssistantConversation,
  aiAssistantConversationStreamResponse,
  aiAssistantConversations,
} from "../routes/Api";
import styles from "./AiAssistant.css";

declare global {
  interface Window {
    openAiAssistant: VoidFunction;
  }
}

export type AiAssistantController = {
  readonly isOpen: boolean;
  close(): void;
};

export function useAiAssistantController(): AiAssistantController {
  const [isOpen, setIsOpen] = React.useState(
    Boolean(sessionStorage.getItem("aiAssistantConversationId"))
  );

  React.useEffect(() => {
    window.openAiAssistant = () => setIsOpen(true);
  }, []);

  return {
    isOpen,
    close() {
      setIsOpen(false);
    },
  };
}

export function AiAssistant({ onClose }: { onClose: () => void }) {
  const [loading, setLoading] = React.useState(true);
  const [sending, setSending] = React.useState(false);
  const [error, setError] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [conversation, setConversation] =
    React.useState<AiAssistantConversationData>(null);
  const messagesRef = React.useRef<HTMLDivElement>(null);

  // Ensure that the last message is visible if an existing conversation
  // is loaded
  const messagesRefCallback = React.useCallback((node: HTMLDivElement) => {
    if (node) {
      node.scrollTop = node.scrollHeight;
      messagesRef.current = node;
    }
  }, []);

  function handleKeyDown(event: React.KeyboardEvent) {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();

      (event.target as HTMLTextAreaElement).form?.dispatchEvent(
        new Event("submit", { cancelable: true, bubbles: true })
      );
    }
  }

  function resizeTextarea(textarea: HTMLTextAreaElement) {
    textarea.style.height = "auto";
    textarea.style.height = textarea.scrollHeight + "px";
  }

  async function submit(submitEvent: React.FormEvent) {
    submitEvent.preventDefault();
    setError("");
    setSending(true);

    // Store message and conversation for reverting in case of error
    const prevMessage = message;
    const prevConversation = conversation;

    setMessage("");

    let serverConversation: AiAssistantConversationData;

    try {
      serverConversation = await createOrUpdateConversation();
    } catch (e) {
      setError(e.message);
      setSending(false);

      // If the conversation/message wasn't created/added successfully,
      // revert to the previous state
      revertOptimisticUi();
      return;
    }

    try {
      // Using serverConversation.id because if it's a new conversation,
      // the state (conversation.Id) won't have updated yet.
      const eventSource = new EventSource(
        aiAssistantConversationStreamResponse(serverConversation.id)
      );

      eventSource.onmessage = event => {
        const data = JSON.parse(event.data) as AiAssistantStreamData;

        if (data.error) {
          setError("Error receiving response.");
          revertOptimisticUi();
          eventSource.close();
          return;
        }

        if (data.done) {
          finishMessage(eventSource, data);
          return;
        }

        addToMessage(data);
      };

      eventSource.onerror = () => {
        setError("Error receiving response.");
        revertOptimisticUi();
        eventSource.close();
        setSending(false);
      };
    } catch (e) {
      setError(e.message);
      setSending(false);
    }

    function revertOptimisticUi() {
      setMessage(prevMessage);
      setConversation(prevConversation);
    }
  }

  function handleClose() {
    sessionStorage.removeItem("aiAssistantConversationId");
    onClose();
  }

  async function createOrUpdateConversation() {
    // Optimistic UI for the user's message
    // If a conversation exists, add the message to it
    // If not, create a new conversation and add the message to it
    if (!conversation) {
      setConversation({
        id: "",
        title: "",
        messages: [
          {
            role: "User",
            message: message,
            references: [],
          },
          {
            role: "Agent",
            message: "",
            references: [],
          },
        ],
      });

      const serverConversation = await createConversation(message);

      setConversation({
        id: serverConversation.id,
        title: serverConversation.title,
        messages: [
          ...serverConversation.messages,
          {
            role: "Agent",
            message: "",
            references: [],
          },
        ],
      });

      // Store the conversation ID in the users session
      sessionStorage.setItem(
        "aiAssistantConversationId",
        serverConversation.id
      );

      return serverConversation;
    } else {
      setConversation(curr => ({
        ...curr,
        messages: [
          ...curr.messages,
          {
            role: "User",
            message: message,
            references: [],
          },
          {
            role: "Agent",
            message: "",
            references: [],
          },
        ],
      }));

      await addMessageToConversation(conversation.id, message);

      return conversation;
    }
  }

  function addToMessage(data: AiAssistantStreamData) {
    setConversation(curr => {
      if (!curr) {
        return curr;
      }

      // Append new streamed text to the latest agent message
      const newMessages = [...curr.messages];
      const lastMessage = newMessages[newMessages.length - 1];

      if (lastMessage.role === "Agent") {
        lastMessage.message += data.message;
      }

      return {
        ...curr,
        messages: newMessages,
      };
    });
  }

  function finishMessage(
    eventSource: EventSource,
    data: AiAssistantStreamData
  ) {
    eventSource.close();
    setSending(false);

    setConversation(curr => {
      if (!curr) {
        return curr;
      }

      // Add conversation ID and references
      const newMessages = [...curr.messages];
      const lastMessage = newMessages[newMessages.length - 1];

      if (lastMessage.role === "Agent") {
        if (data.references?.length > 0) {
          lastMessage.references = data.references;
        }
      }

      return {
        ...curr,
        messages: newMessages,
      };
    });
  }

  // Attempt to load an existing conversation when the component mounts
  React.useEffect(() => {
    async function loadConversation() {
      const conversationId = sessionStorage.getItem(
        "aiAssistantConversationId"
      );

      if (!conversationId) {
        setLoading(false);
        return;
      }

      try {
        const response = await insightFetch(
          `${aiAssistantConversation(conversationId)}`
        );

        if (!response.ok) {
          throw new Error("Error loading chat");
        }

        const responseJson = await response.json();
        setConversation(responseJson);
      } catch (e) {
        setError(e.message);
      } finally {
        setLoading(false);
      }
    }

    loadConversation();
  }, []);

  // Scroll when as message is added to the conversation
  // to ensure that it's visible
  React.useEffect(() => {
    if (messagesRef.current) {
      messagesRef.current.scrollTop = messagesRef.current.scrollHeight;
    }
  }, [conversation?.messages]);

  return (
    <div className={styles.assistant}>
      <div className={styles.header}>
        <Button
          className={styles.close}
          variant="flat"
          title="Close"
          onClick={handleClose}
        >
          &times;
        </Button>
        <h4 className={styles.title}>Insight AI Assistant</h4>
      </div>

      {loading ? (
        <Spinner />
      ) : (
        <div className={styles.body}>
          <Stack>
            <div ref={messagesRefCallback} className={styles.messages}>
              <AgentMessage
                message={{
                  role: "Agent",
                  message: "Hello! How can I help you today?",
                  references: [],
                }}
              />

              {conversation?.messages.map((message, index) => {
                if (message.role === "User") {
                  return <UserMessage key={index} message={message} />;
                } else {
                  return (
                    <AgentMessage
                      key={index}
                      sending={sending}
                      message={message}
                    />
                  );
                }
              })}
            </div>

            {error && <Alert type="error">{error}</Alert>}

            <form className={styles.form} onSubmit={submit}>
              <textarea
                className={styles.textarea}
                rows={1}
                value={message}
                required
                autoFocus={true}
                onChange={e => setMessage(e.target.value)}
                onInput={e => resizeTextarea(e.currentTarget)}
                onKeyDown={handleKeyDown}
              />
              <Button
                type="submit"
                variant="primary"
                icon="paper-plane"
                title="Send"
                busy={sending}
              />
            </form>
          </Stack>
        </div>
      )}
    </div>
  );
}

function UserMessage({ message }: { message: AiAssistantMessage }) {
  return (
    <div className={styles.message} data-user="true">
      <p>{message.message}</p>
    </div>
  );
}

function AgentMessage({
  sending = false,
  message,
}: {
  sending?: boolean;
  message: AiAssistantMessage;
}) {
  if (sending && message.message === "") {
    return (
      <div className={styles.message}>
        <span className={styles.ellipsis}>
          <span>.</span>
          <span>.</span>
          <span>.</span>
        </span>
      </div>
    );
  }

  return (
    <div className={styles.message}>
      <p>{message.message}</p>

      {message.references.length > 0 && (
        <>
          <h5 className={styles.sourcesHeader}>Sources</h5>
          <ul className={styles.sources}>
            {message.references.map((reference, index) => (
              <li key={index}>
                <a href={reference.url} target="_blank" rel="noreferrer">
                  {reference.title}
                </a>
              </li>
            ))}
          </ul>
        </>
      )}
    </div>
  );
}

async function createConversation(
  message: string
): Promise<AiAssistantConversationData> {
  const response = await insightFetch(aiAssistantConversations(), {
    method: "POST",
    body: { message },
  });

  if (!response.ok) {
    throw new Error("Error creating conversation.");
  }

  return await response.json();
}

async function addMessageToConversation(
  conversationId: string,
  message: string
): Promise<AiAssistantConversationData> {
  const response = await insightFetch(aiAssistantConversation(conversationId), {
    method: "POST",
    body: { message },
  });

  if (!response.ok) {
    throw new Error("Error sending message.");
  }

  return await response.json();
}
