import { IconName } from "@fortawesome/fontawesome-svg-core";
import classNames from "classnames";
import * as React from "react";
import Icon, { IconStyle } from "./Icon";
import Spinner from "./Spinner";
import styles from "./Button.css";

export type ButtonVariant =
  | "primary"
  | "warning"
  | "danger"
  | "success"
  | "info"
  | "inverse"
  | "link"
  | "flat";

type CommonProps = {
  type?: "button" | "submit" | "reset";
  variant?: ButtonVariant;
  size?: "mini" | "small" | "medium" | "large" | "block";
  id?: string;
  className?: string;
  icon?: IconName;
  iconStyle?: IconStyle;
  onClick?: (e: React.MouseEvent) => void;
  style?: React.CSSProperties;
  children?: React.ReactNode;
  "data-test-id"?: string;
  title?: string;
  disabled?: boolean;
  form?: string;
  tabIndex?: number;
  name?: string;
  value?: string;
};

type ButtonProps = {
  href?: false;
  target?: never;
  rel?: never;
  download?: never;
  busy?: boolean;
};

type LinkProps = {
  href: string;
  target?: string;
  rel?: string;
  download?: boolean;
  busy?: never;
};

type UnionProps = ButtonProps | LinkProps;

export type Props = CommonProps & UnionProps;

const Button = React.forwardRef<HTMLElement, Props>(
  (
    {
      type = "button",
      variant,
      size,
      className,
      icon,
      iconStyle = "fas",
      onClick,
      disabled,
      href,
      target,
      rel,
      download,
      busy,
      style,
      children,
      ...rest
    },
    ref
  ) => {
    const Tag = href ? "a" : "button";

    return (
      <Tag
        ref={
          // Hack: React types don't allow forwarding refs to different types of elements.
          // But in reality this doesn't cause any issues.
          // Thus the weird cast here!
          ref as React.ForwardedRef<HTMLButtonElement> &
            React.ForwardedRef<HTMLAnchorElement>
        }
        type={type}
        className={classNames(className, styles.btn, {
          [styles[variant]]: variant,
          [styles[size]]: size,
        })}
        onClick={onClick}
        disabled={busy || disabled}
        href={href || null}
        target={target}
        rel={rel}
        download={download}
        style={style}
        {...rest}
      >
        {busy ? (
          <>
            <Spinner />{" "}
          </>
        ) : icon ? (
          <>
            <Icon icon={icon} iconStyle={iconStyle} />{" "}
          </>
        ) : null}
        {children}
      </Tag>
    );
  }
);

/** Useful when styling a react-router <Link /> */
export function buttonClassName(
  variant?: CommonProps["variant"],
  size?: CommonProps["size"]
) {
  return classNames(styles.btn, {
    [styles[variant]]: variant,
    [styles[size]]: size,
  });
}

export default Button;

export function ButtonGroup({ children }: { children: React.ReactNode }) {
  return <div className={styles.buttonGroup}>{children}</div>;
}
