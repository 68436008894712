import styles from "./App.css";

export function Error() {
  return (
    <div className={styles.errorContainer}>
      <h1>Sorry, there was a problem loading this page</h1>
      <p>
        You can{" "}
        <a href="javascript: history.go(-1)">go back to the page you were on</a>
        , give us a call on 020 3393 4005 or email us at{" "}
        <a href="mailto:support@insighttracking.com">
          support@insighttracking.com
        </a>{" "}
        for help.
      </p>
    </div>
  );
}
