// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PXqUlRbFYZMTXuoPA1eS {
  display: grid;
  gap: 10px;
}
`, "",{"version":3,"sources":["webpack://./shared/Stack.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,SAAS;AACX","sourcesContent":[".stack {\n  display: grid;\n  gap: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"stack": `PXqUlRbFYZMTXuoPA1eS`
};
export default ___CSS_LOADER_EXPORT___;
