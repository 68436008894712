import classnames from "classnames";
import * as React from "react";
import { UserOrganisationData } from "@server-types/users";
import Icon from "../Icon";
import {
  DestinationOrganisation,
  getOrganisationPageUrl,
} from "./organisationPageUrl";
import selectStyles from "./OrganisationsSelect.css";
import styles from "./index.css";

const tetherStyle = (el: Element): React.CSSProperties => {
  const { right, bottom, width } = el.getBoundingClientRect();
  return {
    display: "block",
    left: "auto",
    right: window.innerWidth - right,
    top: bottom,
    position: "fixed",
    minWidth: width,
  };
};

export const OrganisationSelect = ({
  currentOrganisationName,
  organisations,
}: {
  currentOrganisationName: string;
  organisations: UserOrganisationData[];
}) => {
  const [isSelectVisible, setIsSelectVisible] = React.useState(false);
  const [searchText, setSearchText] = React.useState("");
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [popupPosition, setPopupPosition] =
    React.useState<React.CSSProperties>(null);
  const ref = React.useRef<HTMLDivElement>(null);

  const filteredOrganisations = React.useMemo<UserOrganisationData[]>(
    () =>
      organisations
        .filter(
          org =>
            org.name.toLowerCase().includes(searchText.toLowerCase()) ||
            org.organisations.some(childOrg =>
              childOrg.name.toLowerCase().includes(searchText.toLowerCase())
            )
        )
        .map(org => ({
          ...org,
          organisations: org.organisations.filter(childOrg =>
            childOrg.name.toLowerCase().includes(searchText.toLowerCase())
          ),
        })),
    [organisations, searchText]
  );

  // Flatten the organisations into a single array, so that we can use the activeIndex
  const flatOrganisations = React.useMemo<UserOrganisationData[]>(() => {
    const flatOrgs: UserOrganisationData[] = [];
    filteredOrganisations.forEach(org => {
      flatOrgs.push(org);
      org.organisations.forEach(childOrg => flatOrgs.push(childOrg));
    });
    return flatOrgs;
  }, [filteredOrganisations]);

  React.useEffect(() => {
    // When a click happens outside of this search component,
    // then we hide the popup.
    // This is instead of using the input's onBlur event,
    // which is tricky when need to click the search result elements.
    function documentClicked(e: MouseEvent) {
      const isClickOutsideComponent =
        ref.current && !ref.current.contains(e.target as Node);
      if (isClickOutsideComponent) {
        setSearchText("");
        setIsSelectVisible(false);
      }
    }
    document.documentElement.addEventListener("click", documentClicked, false);
    return () =>
      document.documentElement.removeEventListener(
        "click",
        documentClicked,
        false
      );
  }, []);

  function onChange(e: React.ChangeEvent<HTMLInputElement>) {
    setSearchText(e.target.value);
  }

  function onKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    switch (e.key) {
      case "ArrowDown":
        e.stopPropagation();
        e.preventDefault();
        if (activeIndex < flatOrganisations.length - 1) {
          setActiveIndex(activeIndex + 1);
        }
        break;

      case "ArrowUp":
        e.stopPropagation();
        e.preventDefault();
        if (activeIndex > 0) {
          setActiveIndex(activeIndex - 1);
        }
        break;

      case "Enter":
        e.stopPropagation();
        e.preventDefault();
        if (flatOrganisations.length > 0) {
          goToOrganisation(activeIndex);
        }
        break;

      case "Tab":
      case "Escape":
        setIsSelectVisible(false);
        setSearchText("");
        setActiveIndex(0);
        break;
    }
  }

  function goToOrganisation(index: number) {
    window.location.href = flatOrganisations[index].href;
  }

  const callbackRef = React.useCallback((node: HTMLDivElement) => {
    ref.current = node;

    if (node) {
      setPopupPosition(tetherStyle(node.querySelector("input")));
    }
  }, []);

  if (isSelectVisible) {
    return (
      <div ref={callbackRef} className={selectStyles.organisationSearch}>
        <div className={styles.search}>
          <label htmlFor="header-school-search">
            <Icon icon="search" />
          </label>

          <input
            className={styles.searchInput}
            placeholder="Search schools..."
            id="header-school-search"
            type="text"
            value={searchText}
            onChange={onChange}
            onKeyDown={onKeyDown}
            autoFocus
          />

          {!!filteredOrganisations.length && (
            <OrganisationsPopup
              positionStyles={popupPosition}
              organisations={filteredOrganisations}
              flatOrganisations={flatOrganisations}
              searchText={searchText}
              activeIndex={activeIndex}
            />
          )}

          {searchText && !filteredOrganisations.length && (
            <div
              className={classnames("dropdown-menu", selectStyles.messagePopup)}
              style={popupPosition}
            >
              {organisations.some(org => org.type === "trust")
                ? "No schools or trusts found"
                : "No schools found"}
            </div>
          )}
        </div>
      </div>
    );
  }

  return (
    <div
      className={selectStyles.organisationSelect}
      onClick={() => setIsSelectVisible(true)}
    >
      <div className={selectStyles.organisationSelectText}>
        {currentOrganisationName}
      </div>
    </div>
  );
};

type OrganisationsPopupProps = {
  positionStyles: React.CSSProperties;
  organisations: UserOrganisationData[];
  flatOrganisations: UserOrganisationData[];
  searchText: string;
  activeIndex: number;
};

const OrganisationsPopup = ({
  positionStyles,
  organisations,
  flatOrganisations,
  searchText,
  activeIndex,
}: OrganisationsPopupProps) => {
  const popupRef = React.useRef<HTMLUListElement>(null);

  return (
    <ul
      ref={popupRef}
      className={classnames("dropdown-menu", selectStyles.menuPopup)}
      style={positionStyles}
    >
      {organisations.map(organisation => {
        return (
          <Organisation
            key={organisation.id}
            organisation={organisation}
            flatOrganisations={flatOrganisations}
            searchText={searchText}
            activeIndex={activeIndex}
            popupRef={popupRef}
          />
        );
      })}
    </ul>
  );
};

type OrganisationProps = {
  organisation: UserOrganisationData;
  parentOrganisation?: UserOrganisationData;
  flatOrganisations: UserOrganisationData[];
  searchText: string;
  activeIndex: number;
  popupRef: React.MutableRefObject<HTMLUListElement>;
};

const Organisation = ({
  organisation,
  parentOrganisation,
  flatOrganisations,
  searchText,
  activeIndex,
  popupRef,
}: OrganisationProps) => {
  const ref = React.useRef<HTMLAnchorElement>(null);
  const index = flatOrganisations.findIndex(org => org.id === organisation.id);

  // Keep the active item in view when using the keyboard to navigate
  if (activeIndex === index && popupRef.current && ref.current) {
    const popupScroll = popupRef.current.scrollTop;
    const popupHeight = popupRef.current.clientHeight;
    const popupHeightPlusScroll = popupHeight + popupScroll;

    const eleOffset = ref.current.offsetTop;
    const eleHeight = ref.current.clientHeight;

    if (eleOffset < popupScroll) {
      popupRef.current.scrollTop = eleOffset;
    } else if (eleOffset + eleHeight > popupHeightPlusScroll) {
      popupRef.current.scrollTop = eleOffset + eleHeight - popupHeight;
    }
  }

  const destinationOrganisation: DestinationOrganisation =
    organisation.type == "school"
      ? {
          type: "school",
          shortId: organisation.id,
          parent: parentOrganisation
            ? {
                type: "trust",
                shortId: parentOrganisation.id.split("/")[1],
                parent: null,
              }
            : null,
        }
      : { type: "trust", shortId: organisation.id.split("/")[1], parent: null };
  const href = getOrganisationPageUrl(
    destinationOrganisation,
    organisation.href
  );

  return (
    <li
      className={classnames({
        active: activeIndex === index,
        [selectStyles.organisation]: true,
        [selectStyles.trust]:
          organisation.type === "trust" &&
          organisation.organisations.length > 0,
      })}
    >
      <a ref={ref} href={href}>
        {organisation.name}
      </a>

      {organisation.organisations.length > 0 && (
        <ul className={classnames("dropdown-menu", selectStyles.schools)}>
          {organisation.organisations.map(childOrg => (
            <Organisation
              key={childOrg.id}
              organisation={childOrg}
              parentOrganisation={organisation}
              flatOrganisations={flatOrganisations}
              searchText={searchText}
              activeIndex={activeIndex}
              popupRef={popupRef}
            />
          ))}
        </ul>
      )}
    </li>
  );
};
