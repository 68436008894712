import * as React from "react";
import Button from "../Button";
import { insightFetch } from "../insightFetch";

export function postErrorToServer(error: Error) {
  insightFetch("/api/error-log", {
    method: "POST",

    body: {
      message: error.message,
      stack: error.stack,
      url: location.toString(),
    },
  });
}

// This is a React Error Boundary component.
// It will catch unhandled errors thrown during render
// and report them back to the server.
export default class LogErrorToServer extends React.Component<
  { children: React.ReactNode },
  { error: boolean }
> {
  constructor(props) {
    super(props);
    this.state = { error: false };
  }

  static getDerivedStateFromError() {
    return { error: true };
  }

  componentDidCatch(error) {
    if (DEBUG) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
    postErrorToServer(error);
  }

  render() {
    if (this.state.error) {
      return (
        <div style={{ padding: 40 }}>
          <h1>Sorry, an error has occurred on this page</h1>
          <p>
            This problem has been logged with the Insight technical team. If you
            require help, please contact support@insighttracking.com or call 020
            3393 4005.
          </p>
          <p>
            <Button variant="primary" onClick={() => location.reload()}>
              Reload Page
            </Button>
          </p>
        </div>
      );
    }
    return this.props.children;
  }
}
